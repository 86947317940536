import React, { useRef } from "react";
import "../App.css";
import logo from "../assets/ARMetalLogo.png";
import contactCircle from "../assets/contactCircle.png";
import heapNuts from "../assets/heapNuts.png";
import hexCuts from "../assets/hexCuts.png";
import verLine from "../assets/verLine.svg";
import circles from "../assets/circles.png";

function Contact() {
  const handleSendEmail = () => {
    window.location.href =
      "mailto:info@armetal.kz?subject=Hello &body=Dear AR Metal team,";
  };
  const textToCopy = "info@armetal.kz";
  const textRef = useRef(null);

  const handleCopyClick = () => {
    // Select the text inside the textRef element
    textRef.current.select();
    document.execCommand("copy");
    // Deselect the text
    window.getSelection().removeAllRanges();
  };
  return (
    <div id="Contact" className="snap-start relative min-h-screen bg-white">
      {/* modal box */}
      <div
        className="absolute top-1/2 left-1/2 md:left-[35%] transform -translate-x-1/2 -translate-y-1/2
        md:pb-16 md:pr-16
      bg-arm-green border border-white rounded-2xl font-Roboto p-6 mx-auto z-10"
      >
        <div className="flex flex-row">
          <h1 className="text-center text-5xl font-bold text-white md:text-left p-3 m-3">
            Contact
          </h1>
          <p className="absolute invisible md:visible md:relative text-lg font-Roboto font-th px-1 md:pt-6 text-white text-center">
            ///
          </p>
        </div>
        <div>
          <img
            className="absolute pointer-events-none invisible md:visible top-0 right-0 w-[120px] p-6 "
            src={logo}
            alt=""
          />
        </div>
        {/* contact information */}
        <div className="">
          {/* div that seperates line and text */}
          <div className="flex p-6">
            <img
              className="absolute invisible md:relative md:visible stroke-white fill-white px-6"
              src={verLine}
              alt="Vertical line"
            />
            {/* div of contact information */}
            <div className="flex flex-col justify-between py-6">
              {/* Mail */}
              <div className="flex flex-col text-center md:flex-row md:text-left p-3">
                <div className="mx-auto md:mx-0 md:pr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="w-9 h-full"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </div>
                <textarea
                  ref={textRef}
                  value={textToCopy}
                  readOnly
                  style={{ position: "absolute", left: "-9999px" }}
                />
                <button
                  onClick={handleCopyClick}
                  className="text-xl font-normal py-3 md:py-0 text-white "
                >
                  info@armetal.kz
                </button>
              </div>

              {/* Address */}
              <div className="flex flex-col text-center md:flex-row md:text-left p-3">
                <div className="mx-auto md:mx-0 md:pr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="w-9 h-full"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>
                </div>
                <p className="text-xl py-3 md:py-0 font-normal text-white ">
                  Mangystau region, Aktau city, 5th microdistrict.
                </p>
              </div>
              {/* Additional Information */}
              <div className="px-3">
                <div className="flex flex-col text-center md:flex-row md:text-left p-6 border border-white rounded-xl">
                  <h1 className="text-3xl text-white px-3 font-black">*</h1>
                  <p className="text-lg font-light text-white">
                    Currently we operate by mail only.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="absolute invisible md:visible bottom-4 right-4 bg-white text-arm-green hover:text-white
            font-black
             px-6 py-2 rounded-lg hover:bg-arm-green border border-white"
            >
              <button onClick={handleSendEmail}>Send Mail</button>
            </div>
          </div>
        </div>
      </div>
      <img
        className="absolute pointer-events-none invisible md:visible left-0 w-1/2 h-full z-0"
        src={contactCircle}
        alt="Left Side Circle"
      />
      <img
        className="absolute pointer-events-none invisible md:visible bottom-0 right-0 w-1/3 xl:w-[30%] md:px-6 mx-6 z-0"
        src={heapNuts}
        alt="Heap Nuts"
      />
      <img
        className="absolute pointer-events-none invisible md:visible top-0 right-0 w-[15%] z-0"
        src={hexCuts}
        alt="Hex cuts"
      />
      <img
        className="h-full pointer-events-none absolute md:invisible top-0 right-0 z-0"
        src={circles}
        alt="Background circles"
      />
    </div>
  );
}
export default Contact;
