import "../App.css";
import logo from "../assets/ARMetalLogo.png";

function Loading() {
  return (
    <div id="Loading" className="snap-start relative min-h-screen bg-white">
      <img
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[30%] md:w-[150px]"
        src={logo}
        alt="Loading, please wait..."
      />
    </div>
  );
}
export default Loading;
