import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Main from "./components/main.jsx";
import MainPage from "./components/mainPage.jsx";
import AboutUs from "./components/aboutUs.jsx";
import WhyUs from "./components/whyUs.jsx";
import OurVision from "./components/ourVision.jsx";
import Contact from "./components/contact.jsx";
import EndLetter from "./components/endLetter.jsx";
import ErrorPage from "./components/errorPage.jsx";

function App() {
  useEffect(() => {
    document.title = `AR Metal | Metal Scrap Trading`;
  }, []);
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route path="/gallery/photographs" element={<Photographs />} /> */}
          {/* <Route path="*" element={<Error404 />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
