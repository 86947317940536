import "../App.css";
import logo from "../assets/ARMetalLogo.png";
import mobileCircle from "../assets/mobileCircle.png";
import endCircle from "../assets/endCircle.png";
import greenCircle from "../assets/greenCircle.png";
import blackCircle from "../assets/blackCircle.png";
// Change circles to svg files so it is easier to controll over properties

function EndLetter() {
  return (
    <div id="EndLetter" className="snap-start relative min-h-screen bg-white">
      {/* Circle texts */}
      {/* Whole Container */}
      <div>
        <div className="absolute bottom-0 z-10">
          <div className="font-Roboto text-white p-3">
            {/* Logo for Mobile version */}
            <img
              className="md:absolute md:invisible w-[25%] h-full mx-auto px-6 cursor-pointer md:w-[150px] 2xl:w-[170px] z-30"
              src={logo}
              alt=""
            />
            <h1 className="text-2xl md:text-3xl 2xl:text-4xl mx-auto md:mx-0 font-medium w-[80%] md:w-[40%] 2xl:w-[45%] p-3 px-6 text-center md:text-left bg-arm-green rounded-full">
              We value your business and appreciate the opportunity to
              contribute to your success.
            </h1>
            {/* bottom text */}
            <div className="flex justify-center md:justify-normal flex-col md:flex-row p-3 ">
              <img
                className="absolute invisible md:relative md:visible mx-0 px-3 cursor-pointer w-[125px] 2xl:w-[125px] h-full z-30"
                src={logo}
                alt=""
              />
              <div className="flex flex-col md:p-6">
                <h2 className="text-xl font-medium text-center md:text-start bg-arm-green">
                  info@armetal.kz
                </h2>
                <p className="text-xl font-light text-center md:text-start bg-arm-green">
                  Mangystau region, Aktau city, <br />
                  5th microdistrict.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* end of main page */}
        <img
          className="absolute md:invisible bottom-0 z-0"
          src={mobileCircle}
          alt=""
        />
        <img
          className="absolute w-[70%] md:w-[95%] lg:w-[50%] invisible md:visible bottom-0 left-0 z-0"
          src={endCircle}
          alt=""
        />
      </div>
      {/* Small Circles */}
      <div className="absolute md:bottom-0 md:pb-28 md:p-6 md:right-0">
        <img
          className="p-3 h-full w-[60px]"
          src={greenCircle}
          alt="Green Small Circle"
        />
        <img
          className="p-3 h-full w-[60px]"
          src={blackCircle}
          alt="Green Small Circle"
        />
        <img
          className="p-3 h-full w-[60px]"
          src={greenCircle}
          alt="Green Small Circle"
        />
      </div>

      {/* Small text */}
      <p className="absolute invisible md:visible bottom-0 right-0 p-3 m-6 mb-9 text-xl font-light text-center md:text-start bg-white border rounded-xl border-arm-green">
        2023 / <span className="text-arm-green font-medium">AR</span> Metal
        Impex LTD
      </p>
    </div>
  );
}
export default EndLetter;
