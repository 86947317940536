import "../App.css";
import logo from "../assets/ARMetalLogo.png";
import lines from "../assets/sideLines.png";
import logisticalExcellence from "../assets/logisticalExcellence.png";
import regionalExpertise from "../assets/regionalExpertise.png";
import businessEthics from "../assets/businessEthics.png";

function WhyUs() {
  return (
    <div
      id="WhyUs"
      className="snap-start relative min-h-screen max-h-screen bg-white"
    >
      {/* Title */}
      <div className="absolute left-1/2 transform -translate-x-1/2 py-9 md:transform-none md:left-0 md:p-16 z-30">
        <div className="flex flex-row">
          <h1 className="text-5xl md:text-6xl font-Roboto font-bold text-arm-green bg-white">
            Why us ?
          </h1>
          <p className="absolute invisible md:visible md:relative text-2xl md:text-4xl font-Roboto font-light px-1 md:pt-6 md:px-3 text-arm-gray text-center">
            ///
          </p>
        </div>
      </div>
      {/* Main Page Container */}
      <div className="flex flex-col p-6 md:p-12 z-20">
        <p className="text-lg md:w-[65%] md:text-xl mac:text-[1.5rem] font-Roboto font-light text-arm-black text-justify p-6 pt-24 mac:m-3 mac:leading-snug bg-white">
          We stand out in the industry with our experienced and expert staff.
          With our{" "}
          <span className="font-black md:font-bold text-arm-green">
            customer-oriented approach
          </span>
          , we always prioritize customer satisfaction.
        </p>
        {/* Cards */}
        <div className="z-50">
          <div className=" flex flex-col md:flex-row">
            {/* First Card */}
            <article class="m-3 md:m-9 overflow-hidden rounded-lg border border-gray-100 bg-white shadow-sm z-10">
              <img
                alt="Logistical Excellence"
                src={logisticalExcellence}
                class="pointer-events-none absolute invisible md:relative md:visible h-32 mac:h-52 w-full object-cover"
              />

              <div class="p-4 sm:p-6">
                <a href="#Contact">
                  <h3 class="text-lg font-medium text-gray-900">
                    Our Logistical Excellence
                  </h3>
                </a>

                <p class="mt-2 line-clamp-3 text-sm/relaxed text-gray-500">
                  With a robust logistics network, we facilitate seamless
                  transactions from collection to delivery, ensuring timely and
                  secure shipments.
                </p>

                <a
                  href="#Contact"
                  class="group mt-4 inline-flex items-center gap-1 text-sm font-bold text-arm-green"
                >
                  Contact us
                  <span
                    aria-hidden="true"
                    class="block transition-all group-hover:ms-0.5 rtl:rotate-180"
                  >
                    &rarr;
                  </span>
                </a>
              </div>
            </article>
            <article class="m-3 md:m-9 overflow-hidden rounded-lg border border-gray-100 bg-white shadow-sm z-10">
              <img
                alt="Regional Expertise"
                src={regionalExpertise}
                class="pointer-events-none absolute invisible md:relative md:visible h-32 mac:h-52 w-full object-cover"
              />

              <div class="p-4 sm:p-6">
                <a href="#Contact">
                  <h3 class="text-lg font-medium text-gray-900">
                    Our Regional Expertise
                  </h3>
                </a>

                <p class="mt-2 line-clamp-3 text-sm/relaxed text-gray-500">
                  Our in-depth understanding of the Kazakh, European, Caspian,
                  and CIS markets allows us to navigate complexities
                  efficiently.
                </p>

                <a
                  href="#Contact"
                  class="group mt-4 inline-flex items-center gap-1 text-sm font-bold text-arm-green"
                >
                  Contact us
                  <span
                    aria-hidden="true"
                    class="block transition-all group-hover:ms-0.5 rtl:rotate-180"
                  >
                    &rarr;
                  </span>
                </a>
              </div>
            </article>
            <article class="m-3 md:m-9 overflow-hidden rounded-lg border border-gray-100 bg-white shadow-sm z-10">
              <img
                alt="Business Ethics"
                src={businessEthics}
                class="pointer-events-none absolute invisible md:relative md:visible h-32 mac:h-52 w-full object-cover"
              />

              <div class="p-4 sm:p-6">
                <a href="#Contact">
                  <h3 class="text-lg font-medium text-gray-900">
                    Our Business Ethics
                  </h3>
                </a>

                <p class="mt-2 line-clamp-3 text-sm/relaxed text-gray-500">
                  We are determined to be a reliable business partner with our
                  strong business ethics and transparent trading principles.
                </p>

                <a
                  href="#Contact"
                  class="group mt-4 inline-flex items-center gap-1 text-sm font-bold text-arm-green"
                >
                  Contact us
                  <span
                    aria-hidden="true"
                    class="block transition-all group-hover:ms-0.5 rtl:rotate-180"
                  >
                    &rarr;
                  </span>
                </a>
              </div>
            </article>
          </div>
        </div>
      </div>
      <a onClick={() => window.location.reload(false)}>
        <img
          className="absolute cursor-pointer invisible md:visible top-0 right-0 m-3 md:m-6 w-[60px] md:w-[100px] z-30"
          src={logo}
          alt="ARMetal Logo"
          href
        />
      </a>
      <img
        className="absolute bottom-0 right-0 mx-6 md:mx-16 md:w-[45%] mac:w-[47%] z-0"
        src={lines}
        alt="side lines"
        href
      />
    </div>
  );
}
export default WhyUs;
