import "../App.css";
import circle from "../assets/circle.png";
import map from "../assets/mapImage.png";

function AboutUs() {
  return (
    <div id="AboutUs" className="snap-start relative min-h-screen bg-white">
      {/* Title */}
      <div className="absolute left-1/2 transform -translate-x-1/2 py-9 md:transform-none md:left-0 md:p-16 z-30">
        <div className="flex flex-row">
          <h1 className="text-5xl md:text-6xl font-Roboto font-bold text-arm-green">
            About us
          </h1>
          <p className="absolute invisible md:visible md:relative text-2xl md:text-4xl font-Roboto font-light px-1 md:pt-6 md:px-3 text-arm-gray text-center">
            ///
          </p>
        </div>
      </div>
      <div className="absolute top-1/2 transform -translate-y-1/2 pt-24">
        <div className="flex flex-col flex-grow md:flex-row p-9 m-9">
          <img
            className="pointer-events-none pt-32 md:p-0 md:w-[35%] 2xl:w-[45%] h-full md:left-0 md:bottom-0 md:m-6 z-0"
            src={map}
            alt="Map Picture"
          />
          <p className=" text-sm md:text-lg md:w-[50%] 2xl:text-xl font-Roboto font-light text-arm-black text-justify p-6 2xl:m-6 2xl:leading-snug">
            <span className="font-bold text-arm-green">AR Metal Impex LTD</span>{" "}
            is an emerging company in the field of metal trading. Our company,
            has recently established and aims to play a leading role in the
            metal scrap trade with its expert staff and strategic vision. <br />{" "}
            <br /> At AR Metal Impex LTD, we are at the forefront of the metal
            scrap trading industry, connecting
            <span className="font-bold text-arm-green"> Kazakhstan</span> with
            <span className="font-bold text-arm-green"> Europe</span>, countries
            in the
            <span className="font-bold text-arm-green">
              {" "}
              Caspian Sea region
            </span>
            , and the{" "}
            <span className="font-bold text-arm-green">
              Commonwealth of Independent States (CIS)
            </span>
            . Established with a commitment to excellence and sustainability, we
            aim to become a trusted name in the field, dedicated to delivering
            high-quality metal scrap solutions.
          </p>
        </div>
      </div>
      <a href="#WhyUs">
        <img
          className="absolute bottom-0 right-0 m-6 md:m-16 w-[60px] md:w-[70px] z-30"
          src={circle}
          alt="downCircle"
          href
        />
      </a>
    </div>
  );
}
export default AboutUs;
