import "../App.css";
import imgVision from "../assets/imgVision.png";
import verLine from "../assets/verLine.png";
import circles from "../assets/circles.png";

function OurVision() {
  return (
    <div
      id="OurVision"
      className="snap-start relative min-h-screen bg-white z-40"
    >
      {/* Title */}
      <div className="absolute left-1/2 transform -translate-x-1/2 py-9 md:transform-none md:left-0 md:p-16 z-30">
        <div className="flex flex-row">
          <h1 className="text-3xl sm:text-5xl font-Roboto font-bold text-arm-green bg-white px-3 border-white rounded-t-xl">
            Our Vision
          </h1>
          <p className="absolute invisible md:visible md:relative text-2xl mac:text-2xl font-Roboto font-light px-1 md:pt-6 md:px-3 text-arm-gray text-center">
            ///
          </p>
        </div>
      </div>
      <div className="absolute top-1/2 transform -translate-y-1/2 z-10">
        <div className="flex flex-col-reverse md:flex-row p-3 md:px-12">
          {/* Text Side */}
          <div className="m-6 pt-12 xl:w-[50%] mac:w-[50%] font-Roboto font-light text-arm-black ">
            {/* first paragraph */}
            <p className="text-md xl:text-lg p-3 pb-6 text-left mac:m-6 mac:leading-snug mac:text-[1.35rem] bg-white border-white rounded-t-xl">
              <span className="font-bold text-arm-green">
                AR Metal Impex LTD
              </span>{" "}
              aims to gain a solid place in the metal scrap trading industry.
              Our mission is to offer a competitive advantage to our customers
              by adapting to constantly evolving and changing market conditions.
              We envision a circular economy where metal scrap is not just
              recycled but strategically traded to meet the growing demands of
              industries. By fostering responsible trading practices, we aim to
              contribute to the economic and environmental well-being of the
              regions we serve.
            </p>
            {/* second paragraph */}
            <div className="flex px-6 md:pl-12 bg-white border-white rounded-b-xl">
              <img
                className="px-3 h-[350px] sm:h-[270px] mac:h-[375px]"
                src={verLine}
                alt="vertical line"
              />
              {/* Text */}
              <p className="text-md leading-tight mac:text-xl text-left mac:m-3 md:w-[95%]">
                <span className="text-arm-green font-bold text-2xl mac:text-3xl">
                  Our Markets:
                </span>{" "}
                <br /> <br />
                <span className="text-arm-green font-bold text-xl md:text-2xl">
                  Europe:
                </span>{" "}
                We serve as a reliable bridge between Kazakhstan and various
                European markets, facilitating the efficient trade of metal
                scrap materials. <br /> <br />
                <span className="text-arm-green font-bold text-xl md:text-2xl ">
                  Caspian Sea Region:
                </span>{" "}
                Our presence extends across the Caspian Sea region, promoting
                sustainable practices and contributing to regional economic
                development. <br /> <br />
                <span className="text-arm-green font-bold text-xl md:text-2xl ">
                  CIS Countries:
                </span>{" "}
                As part of the CIS community, we engage in mutually beneficial
                trade, fostering collaboration and strengthening economic ties.
              </p>
            </div>
          </div>
          <img
            className="pointer-events-none h-full absolute sm:relative invisible sm:visible w-[25%] sm:w-[45%] mx-auto pt-24 md:p-0 md:w-[30%] mac:w-[35%] mac:pt-12 md:m-3 z-10"
            src={imgVision}
            alt="Gallery"
          />
        </div>
      </div>
      <img
        className="h-full absolute sm:visible top-0 right-0 z-0 pointer-events-none"
        src={circles}
        alt="Gallery"
      />
    </div>
  );
}
export default OurVision;
