import "../App.css";
import logo from "../assets/ARMetalLogo.png";
import mainPic from "../assets/mainPagePicture.png";

function MainPage() {
  return (
    <div id="Home" className="snap-start relative min-h-screen bg-white">
      <a onClick={() => window.location.reload(false)}>
        <img
          className="w-[30%] absolute cursor-pointer md:w-[135px] 2xl:w-[170px] left-1/2 transform -translate-x-1/2 md:transform-none md:left-0 p-6 z-30"
          src={logo}
          alt="Armetal Logo"
        />
      </a>
      <img
        className="absolute px-9 md:p-0 md:pl-32 lg:w-[65%] md:right-0 pointer-events-none z-0"
        src={mainPic}
        alt="Main Page BG"
      />

      {/* Nav Bar */}
      <div className="absolute top-1/2 transform -translate-y-1/2 px-9 text-arm-gray text-2xl lg:text-lg 2xl:text-2xl font-Roboto font-light list-none ">
        <li className="">
          <a
            className="transition transform  ease-in-out hover:bg-arm-green hover:text-white hover:font-medium hover:m-3 hover:px-3 hover:text-4xl"
            href="#AboutUs"
          >
            About us
          </a>
        </li>
        <li className="">
          <a
            className="transition transform  ease-linear   hover:bg-arm-green hover:text-white hover:font-medium hover:m-3 hover:px-3 hover:text-4xl"
            href="#WhyUs"
          >
            Why us ?
          </a>
        </li>
        <li className="">
          <a
            className="transition transform  ease-linear   hover:bg-arm-green hover:text-white hover:font-medium hover:m-3 hover:px-3 hover:text-4xl"
            href="#OurVision"
          >
            Our vision
          </a>
        </li>
        <li className="">
          <a
            className="transition transform  ease-linear   hover:bg-arm-green hover:text-white hover:font-medium hover:m-3 hover:px-3 hover:text-4xl"
            href="#Contact"
          >
            Contact
          </a>
        </li>
      </div>
      <div className="absolute bottom-0 font-Roboto p-9 text-arm-black text-left">
        <p className=" text-2xl 2xl:text-4xl 2xl:py-2 font-medium">
          <span className="text-arm-green">AR</span> Metal Impex LTD
        </p>
        <p className="text-arm-gray 2xl:text-lg text-base font-light">
          Metal scrap trading.
        </p>
        <p className="text-arm-gray 2xl:text-lg text-base font-light">
          From Kazakhstan to Europe,
        </p>
        <p className="text-arm-gray 2xl:text-lg text-base font-light">
          Caspian sea region countries and CIS Countries.
        </p>
      </div>
    </div>
  );
}

export default MainPage;
