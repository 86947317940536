import "../App.css";
import React, { useState, useEffect } from "react";
import MainPage from "./mainPage.jsx";
import AboutUs from "./aboutUs.jsx";
import WhyUs from "./whyUs.jsx";
import OurVision from "./ourVision.jsx";
import Contact from "./contact.jsx";
import EndLetter from "./endLetter.jsx";
import Loading from "./loading.jsx";

function Main() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);
  return (
    <div className="selection:bg-arm-green selection:text-white">
      {loading ? (
        <Loading />
      ) : (
        <section className="snap-mandatory snap-y scroll-auto h-screen overflow-scroll overflow-x-hidden">
          <MainPage />
          <AboutUs />
          <WhyUs />
          <OurVision />
          <Contact />
          <EndLetter />
        </section>
      )}
    </div>
  );
}

export default Main;
